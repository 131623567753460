import { createArray } from 'src/utils';

export const MERCHANTS = [
  {
    id: 0,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-0.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 1,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-1.png'),
    destinationURL: 'https://www.apahotel.com',
  },
  {
    id: 2,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-2.png'),
    destinationURL: 'https://tabiraku.travel/tour/',
  },
  {
    id: 3,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-3.png'),
    destinationURL: 'https://www.wbf.co.jp/kokunai/',
  },
  {
    id: 4,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-4.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
  },
  {
    id: 5,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-5.png'),
    destinationURL:
      'https://www.airtrip.jp/?utm_source=paidy.com&utm_medium=web&utm_campaign=paidy_travelcashbackcampaign_from20230703',
  },
  {
    id: 6,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-6.png'),
    destinationURL: 'https://travelist.jp/',
  },
  {
    id: 7,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-7.png'),
    destinationURL: 'https://www.sorahapi.jp?med_id=paidy',
  },
  {
    id: 8,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-8.png'),
    destinationURL: 'https://www.wbf.co.jp/kokunai/',
  },
  {
    id: 9,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-9.png'),
    destinationURL: 'https://travel.willer.co.jp/?mid=1458',
  },
  {
    id: 10,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-10.png'),
    destinationURL: 'https://www.kosokubus.com/?ad=PDY',
  },
  {
    id: 11,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-11.png'),
    destinationURL: 'https://www.489.fm/',
  },
  {
    id: 12,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-12.png'),
    destinationURL: 'https://vipliner.biz/',
  },
  {
    id: 13,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-13.png'),
    destinationURL: 'https://www.asoview.com/',
  },
  {
    id: 14,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-14.png'),
    destinationURL: 'https://activityjapan.com/',
  },
  {
    id: 15,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-15.png'),
    destinationURL: 'https://oki-raku.net/activity/',
  },
  {
    id: 16,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-16.png'),
    destinationURL: 'https://travel.willer.co.jp/?mid=1458',
  },
  {
    id: 17,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-17.png'),
    destinationURL: 'https://vipliner.biz/',
  },
  {
    id: 18,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-18.png'),
    destinationURL: 'https://www.hoshinoresorts.com/',
  },
  {
    id: 19,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-19.png'),
    destinationURL: 'https://www.apahotel.com',
  },
  {
    id: 20,
    imgLogo: require('src/images/campaign/202307_10MM_merchant_vol2/merchants/logos/logo-20.png'),
    destinationURL: 'https://activityjapan.com/',
  },
];

export const MERCHANT_GROUPED_BY_CATEGORY = [
  {
    categoryValue: 1,
    merchantIds: createArray(0, 3),
  },
  {
    categoryValue: 2,
    merchantIds: createArray(4, 8),
  },
  {
    categoryValue: 3,
    merchantIds: createArray(9, 12),
  },
  {
    categoryValue: 4,
    merchantIds: createArray(13, 17),
  },
  {
    categoryValue: 5,
    merchantIds: createArray(18, 20),
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'ホテル' },
  { value: 2, label: '航空券' },
  { value: 3, label: 'バス' },
  { value: 4, label: 'レジャー' },
  { value: 5, label: 'その他' },
];

export const FIXED_MERCHANTS = [];

export const MERCHANTS_GROUPED_BY_FIXED_TAGS = [];

export const FIXES_TAGS = [];
